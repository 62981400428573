<template>
  <div class="main-layout">
    <!-- Sidebar -->

    <div v-show="isSidebarVisible" class="sidebar">
      <div
        style="
          height: 100px;
          margin: 20px;
          display: flex;
          flex-direction: column;
        "
      >
        <el-button
          @click="toggleSidebar"
          type="primary"
          style="width: 100px; margin-bottom: 10px"
          >隐藏菜单</el-button
        >
        <el-button
          @click="goBack"
          type="primary"
          style="width: 100px; margin-bottom: 10px; margin-left: 0"
          >退出</el-button
        >
        <el-button
          @click="reset"
          type="primary"
          style="width: 100px; margin-left: 0"
          >重置</el-button
        >
      </div>
      <ul>
        <li :class="{ active: isActive('/pcpage') }">
          <router-link to="/pcpage" @click="setActive('/pcpage')">
            <el-icon><Management /></el-icon>
            <div class="font_col">政策管理</div>
          </router-link>
        </li>
        <li :class="{ active: isActive('/account') }">
          <router-link to="/account" @click="setActive('/account')">
            <el-icon><Stamp /></el-icon>
            <div class="font_col">账户管理</div>
          </router-link>
        </li>
      </ul>
    </div>

    <!-- Content Area -->
    <div class="content">
      <!-- Header -->
      <div class="header">
        <el-button
          class="toggle-sidebar-btn"
          type="primary"
          @click="toggleSidebar"
        >
          <span v-if="isSidebarVisible">隐藏菜单</span>
          <span v-else>显示菜单</span>
        </el-button>
        <div class="img_logo"></div>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { Management, Stamp } from "@element-plus/icons-vue";

export default {
  name: "BaseLayout",
  components: {
    Management,
    Stamp,
  },
  data() {
    return {
      activeLink: "",
      isSidebarVisible: false,
    };
  },
  created() {
    this.setActive(this.$route.path);
  },
  watch: {
    $route(to) {
      this.setActive(to.path);
    },
  },
  methods: {
    setActive(link) {
      this.activeLink = link;
    },
    isActive(link) {
      return this.activeLink === link;
    },
    goBack() {
      this.$router.push("/login");
    },
    reset() {
      window.location.reload();
    },
    toggleSidebar() {
      this.isSidebarVisible = !this.isSidebarVisible;
    },
  },
};
</script>

<style scoped>
/* General Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Sidebar Styles */
.sidebar {
  background-color: #333;
  width: 200px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  overflow-y: auto;
}
.font_col {
  color: #fff;
  margin-top: 5px;
}
.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.sidebar li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s, border-bottom 0.3s;
  border-bottom: 1px solid white;
  text-align: center;
}
.sidebar li:hover {
  background-color: #555;
}
.sidebar li.active {
  background-color: #444;
}
.el-icon {
  font-size: 36px;
  color: white;
}
a:-webkit-any-link {
  color: -webkit-link;
  cursor: pointer;
  text-decoration: none;
}

/* Header Styles */
.img_logo {
  width: 300px;
  height: 100px;
  background-image: url("../assets/logo.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 auto;
}
.header {
  background-color: #ffffff;
  padding: 20px;
  min-width: 680px;
}

/* Content Styles */
.main-layout {
  display: flex;
}
.content {
  background-color: #f4f4f4;
  min-height: 100vh;
  overflow-y: auto;
}
</style>
